exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-comissions-js": () => import("./../../../src/pages/comissions.js" /* webpackChunkName: "component---src-pages-comissions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-comissao-js": () => import("./../../../src/templates/comissao.js" /* webpackChunkName: "component---src-templates-comissao-js" */),
  "component---src-templates-projeto-js": () => import("./../../../src/templates/projeto.js" /* webpackChunkName: "component---src-templates-projeto-js" */)
}

